import { NavigationGuard } from 'vue-router/types/router';
import { NavigationGuardNext, Route } from 'vue-router';

function evaluateGuardsRecursive(
  guards: Array<NavigationGuard>,
  index,
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
) {
  if (index >= guards.length) {
    next();
    return;
  }

  const nextGuard = guards[index];

  nextGuard(to, from, (arg) => {
    if (arg === undefined) {
      evaluateGuardsRecursive(guards, index + 1, to, from, next);
      return;
    }

    next(arg);
  });
}

/**
 * Function which allows the use of multiple navigation guards with vue-router.
 *
 * @param guards List of guards to evaluate.
 */
export function multiguard(guards: Array<NavigationGuard>) {
  return (to, from, next) => {
    return evaluateGuardsRecursive(guards, 0, to, from, next);
  };
}
